<template>
  <el-container v-if="observation" id="addobservation">
    <el-header>
      <el-page-header @back="$router.push('/observations')" title="Retour">
        <template #content>
          <span class="text-large font-600 mr-3">
            {{title}}
          </span>
        </template>
      </el-page-header>
    </el-header>
    <el-main>
      <InformationRow v-if="form.informations && form.status === '0'" :obs="form"/>
      <h2> Informations
        <el-button
          v-if="canEdit"
          type="primary"
          style="float: right"
          @click="handleValidate"
        >
          Enregistrer les informations
        </el-button>
      </h2>
      <el-form label-position="left" label-width="230px">
          <el-row :gutter="30">
            <el-col :span="16">
              <el-form-item label="date de saisie" :required="true">
                <el-date-picker
                  :disabled="!canEdit"
                  v-model="form.createdAt"
                  type="datetime"
                  size="small"
                  value-format="X"
                  format="DD-MM-YYYY HH:mm:ss"
                  :clearable="false"
                />
              </el-form-item>
              <el-form-item label="date de débarquement">
                <el-date-picker
                  :disabled="!canEdit"
                  v-model="form.obsAt"
                  type="date"
                  size="small"
                  value-format="X"
                  format="DD-MM-YYYY"
                  placeholder="Choisir une date"
                />
              </el-form-item>
              <el-form-item
                label="catégorie"
                :required="true"
                :error="error.category"
              >
                <el-select
                  :disabled="!canEdit"
                  v-model="obsCategory"
                  placeholder="Choisir"
                  size="small"
                >
                  <el-option value="autre" label="Autre"/>
                  <el-option
                      v-for="c in categories"
                      :key="c.group"
                      :value="c.group"
                      :label="c.name"
                    />
                </el-select>
              </el-form-item>
              <el-form-item
                label="espèce"
                :required="true"
                :error="error.species"
              >
                <el-select
                  v-model="obsSpecies"
                  placeholder="Choisir"
                  size="small"
                  :disabled="!(typeof obsCategory === 'number' || obsCategory === 'autre') || !canEdit"
                  filterable
                  :allow-create="obsCategory === 'autre'"
                >
                  <el-option value="autre" label="Autre"/>
                  <el-option
                    v-for="s in filteredSpecies"
                    :key="s.id"
                    :value="s.id"
                    :label="s.name"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                label="type"
                :required="true"
                :error="error.type"
              >
                <el-select
                  :disabled="!canEdit"
                  v-model="form.type"
                  placeholder="Choisir"
                  size="small"
                >
                  <el-option
                    value="Observation"
                    label="Observation"
                  />
                  <el-option
                    value="Capture"
                    label="Capture"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                label="lieu"
                :required="true"
                :error="error.place"
              >
                <el-select
                  :disabled="!canEdit"
                  v-model="form.place"
                  placeholder="Choisir"
                  size="small"
                >
                  <el-option label="En pêche" value="En pêche"/>
                  <el-option label="En route" value="En route"/>
                  <el-option label="À quai" value="À quai"/>
                </el-select>
              </el-form-item>
              <el-form-item
                :disabled="!canEdit"
                label="nombre"
                :required="true"
                :error="error.number"
              >
                <el-input v-model="form.nombre" size="small" :disabled="!canEdit"/>
              </el-form-item>
              <template v-if="Object.keys(form.data).length > 0">
                <el-form-item label="nombre petit">
                  <el-input v-model="form.data['nombre petit']" size="small" :disabled="!canEdit"/>
                </el-form-item>
                <el-form-item label="nombre grand">
                  <el-input v-model="form.data['nombre grand']" size="small" :disabled="!canEdit"/>
                </el-form-item>
                <el-form-item label="nombre relâchés">
                  <el-input v-model="form.data['nombre relaches']" size="small" :disabled="!canEdit"/>
                </el-form-item>
                <el-form-item label="nombre embarqués">
                  <el-input v-model="form.data['nombre embarques']" size="small" :disabled="!canEdit"/>
                </el-form-item>
                <el-form-item label="nombre accrochés">
                  <el-input v-model="form.data['nombre accroches']" size="small" :disabled="!canEdit"/>
                </el-form-item>
                <el-form-item label="nombre relâchés vivants">
                  <el-input v-model="form.data['nombre relaches vivants']" size="small" :disabled="!canEdit"/>
                </el-form-item>
                <el-form-item label="nombre relâchés morts">
                  <el-input v-model="form.data['nombre relaches mort']" size="small" :disabled="!canEdit"/>
                </el-form-item>
                <el-form-item label="taille moyenne">
                  <el-input v-model="form.data['taille moyenne']" size="small" :disabled="!canEdit"/>
                </el-form-item>
                <el-form-item label="relâchés état">
                  <el-select v-model="form.data['relaches etat']" size="small" :disabled="!canEdit">
                    <el-option label="Bon état" value="Bon état"></el-option>
                    <el-option label="Légèrement blessé" value="Légèrement blessé"></el-option>
                    <el-option label="Gravement blessé" value="Gravement blessé"></el-option>
                  </el-select>
                  </el-form-item>
                </template>
              <el-form-item label="informations">
                <el-input v-model="form.informations" type="textarea" :disabled="!canEdit"/>
              </el-form-item>
              <el-form-item label="identification">
                <el-select
                  :disabled="!canEdit"
                  size="small"
                  placeholder="Choisir"
                  v-model="form.identification"
                >
                  <el-option label="Non" :value="false"/>
                  <el-option label="Oui" :value="true"/>
                </el-select>
              </el-form-item>
              <el-form-item label="réponses" v-if="form.identification === true">
                <el-input v-model="form.data['reponses']" size="small" :disabled="!canEdit"/>
              </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-card>
              <template #header>
                <h4>Compte</h4>
              </template>
              <el-form-item label-width="0px" :error="error.clientId">
                <el-select
                  v-if="add"
                  v-model="form.clientId"
                  placeholder="Choisir"
                  style="width: 100%"
                  filterable
                  size="small"
                >
                  <el-option
                    v-for="c in clients"
                    :key="c.id"
                    :label="c.firstName + ' ' + c.lastName + ' ' + '(' + c.email + ')'"
                    :value="c.id"
                  />
                </el-select>
                <el-button
                  v-else
                  type="text"
                  class="client-name"
                  @click="editClient"
                >
                  <span v-if="form.client">{{ form.client['firstName'] + ' ' + form.client['lastName'] }}</span>
                </el-button>
                <AddClient v-model:visible="visible" :client="client"/>
              </el-form-item>
              <Boats v-if="form.clientId" v-model:clientId="form.clientId" v-model:boatName="form.boatName"/>
            </el-card>
            <div class="map-container">
              <GMapMap
                v-if="form.location.latitude && form.location.longitude"
                :center="location"
                :zoom="7"
                style="width: 100%; height: 300px"
                class="map"
              >
                  <GMapMarker
                    :position="location"
                  />
              </GMapMap>

              <div class="location">
                <h5>Localisation GPS</h5>
                <el-input v-model="gpsLocation.latitudeDegree" :disabled="!canEdit"/>°
                <el-input v-model="gpsLocation.latitudeMin" :disabled="!canEdit"/>'
                <el-input v-model="gpsLocation.latitudeSeconds" @blur="decToSec('latitudeSeconds')" :disabled="!canEdit"/>"
                <el-select v-model="gpsLocation.latitudeDir" style="width: 70px" :disabled="!canEdit">
                  <el-option value="N" />
                  <el-option value="S" />
                </el-select>
              </div>

              <div class="location" style="margin-bottom: 30px">
                <el-input v-model="gpsLocation.longitudeDegree" :disabled="!canEdit"/>°
                <el-input v-model="gpsLocation.longitudeMin" :disabled="!canEdit"/>'
                <el-input v-model="gpsLocation.longitudeSeconds" @blur="decToSec('longitudeSeconds')" :disabled="!canEdit" />"
                <el-select v-model="gpsLocation.longitudeDir" style="width: 70px" :disabled="!canEdit">
                  <el-option value="E" />
                  <el-option value="W" />
                </el-select>
              </div>
            </div>
            <div class="photo-container" v-if="form.id">
              <ObsMedias v-model:medias="form.medias" :observationId="form.id"/>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </el-main>
</el-container>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import Boats from './Boats.vue'
import AddClient from './AddClient.vue'
import dms2dec from 'dms2dec'
import { Decimal2DMS } from 'dms-to-decimal'
import ObsMedias from '@/components/observation/medias.vue'
import InformationRow from '@/components/information/row.vue'
export default {
  components: {
    Boats,
    AddClient,
    ObsMedias,
    InformationRow
  },
  props: {
    observation: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      error: {
        category: null,
        species: null,
        type: null,
        location: null,
        number: null,
        place: null,
        clientId: null
      },
      visible: false,
      client: null,
      title: 'Ajouter une nouvelle observation',
      form: {
        boatName: null,
        clientId: null,
        createdAt: moment().unix().toString(),
        data: {
          choix: '',
          'nombre accroches': '',
          'nombre embarques': '',
          'nombre grand': '',
          'nombre petit': '',
          'nombre relaches': '',
          'nombre relaches morts': '',
          'nombre relaches vivants': '',
          picture: '',
          read: '',
          reponses: '',
          sex: '',
          'taille moyenne': ''
        },
        especeId: '',
        fishing: '',
        identification: null,
        informations: '',
        location: {
          latitude: null,
          longitude: null
        },
        nombre: null,
        obsAt: moment().unix().toString(),
        otherEspece: null,
        place: '',
        position: {
          latitude: null,
          longitude: null
        },
        status: '',
        type: 'Observation'
      },
      obsSpecies: null,
      obsCategory: null,
      filteredSpecies: [],
      gpsLocation: {
        latitudeDegree: '',
        latitudeMin: '',
        latitudeSeconds: '',
        latitudeDir: '',
        longitudeDegree: '',
        longitudeMin: '',
        longitudeSeconds: '',
        longitudeDir: ''
      }
    }
  },
  watch: {
    user (val) {
      if (val) {
        this.loadData()
      }
    },
    obsCategory (val) {
      if (typeof val === 'number' || val === 'autre') {
        if (val === 'autre') {
          this.obsSpecies = 'autre'
          this.filteredSpecies = []
        } else {
          this.obsSpecies = null
          this.filteredSpecies = this.species.filter((s) => s.group === +val)
        }
      }
    },
    obsSpecies (val) {
      if (val) {
        this.form.especeId = val
      }
    },
    gpsLocation: {
      handler (val) {
        if (val) {
          const coords = dms2dec([parseFloat(val.latitudeDegree), parseFloat(val.latitudeMin), parseFloat(val.latitudeSeconds)], val.latitudeDir, [parseFloat(val.longitudeDegree), parseFloat(val.longitudeMin), parseFloat(val.longitudeSeconds)], val.longitudeDir)
          if (coords && typeof coords[0] === 'number' && typeof coords[1] === 'number') {
            this.form.location = { latitude: coords[0], longitude: coords[1] }
          }
        }
      },
      deep: true
    },
    species () {
      this.getCategorySpecies(this.form)
    }
  },
  computed: {
    canEdit () {
      return this.user && this.user.role === '1'
    },
    add () {
      return this.$route.params.id === 'add'
    },
    location () {
      return { lat: this.form.location.latitude, lng: this.form.location.longitude }
    },
    ...mapState(['clients', 'user', 'species', 'categories'])
  },
  methods: {
    decToSec (key) {
      const normalCoord = this.gpsLocation[key] && parseFloat(this.gpsLocation[key]) >= 0 && parseFloat(this.gpsLocation[key]) < 60
      const exceedsCoord = this.gpsLocation[key] && parseFloat(this.gpsLocation[key]) > 999

      this.gpsLocation[key] = normalCoord ? this.gpsLocation[key] : (exceedsCoord ? 0 : Math.floor((parseFloat(this.gpsLocation[key]) * 60) / 1000))
    },
    moment () {
      return moment()
    },
    editClient () {
      this.client = this.clients.find((c) => c.id === this.form.clientId)
      this.visible = true
    },
    handleValidate () {
      this.error = {
        category: null,
        species: null,
        type: null,
        place: null,
        number: null,
        clientId: null
      }
      let isError = false
      if (this.form.type === '') {
        this.error.type = 'Ce champ est requis'
      }
      if (this.form.place === '') {
        this.error.place = 'Ce champ est requis'
      }
      if (this.form.nombre === null) {
        this.error.number = 'Ce champ est requis'
      }
      if (this.form.clientId === null) {
        this.error.clientId = 'Ce champ est requis'
      }
      if (this.obsCategory === null) {
        this.error.category = 'Ce champ est requis'
      }
      if (this.obsSpecies === null) {
        this.error.species = 'Ce champ est requis'
      }
      for (let i = 0; i < Object.keys(this.error).length; i++) {
        if (this.error[Object.keys(this.error)[i]]) {
          isError = true
        }
      }
      if (!isError) {
        if (this.obsCategory === 'autre') {
          this.form.especeId = null
          this.form.otherEspece = this.obsSpecies
        }
        if (this.$route.params.id !== 'add') {
          this.$store.dispatch('updateObservation', this.form)
            .then(() => {
              this.$notify({
                title: 'Déclaration modifiée',
                message: 'La déclaration a été modifiée avec succès',
                type: 'success'
              })
              this.$nextTick(() => {
                this.$router.push('/observations')
              })
            })
            .catch((err) => {
              this.$notify({
                title: 'Erreur',
                message: 'Cette déclaration n\'a pas pu être modifiée',
                type: 'error'
              })
              console.error(err)
            })
        } else {
          this.$store.dispatch('createObservation', this.form)
            .then(() => {
              this.$notify({
                title: 'Déclaration ajoutée',
                message: 'La déclaration a été ajoutée avec succès',
                type: 'success'
              })
              this.$nextTick(() => {
                this.$router.push('/observations')
              })
            })
            .catch((err) => {
              this.$notify({
                title: 'Erreur',
                message: 'Cette déclaration n\'a pas pu être ajoutée',
                type: 'error'
              })
              console.error(err)
            })
        }
      }
    },
    loadData () {
      const urlId = this.$route.params.id
      if (urlId !== 'add') {
        this.$store.dispatch('getObservation', urlId)
          .then((obs) => {
            this.getCategorySpecies(obs)
            this.form = {
              ...obs,
              createdAt: obs.createdAt.toString(),
              obsAt: obs.obsAt.toString(),
              data: { ...this.form.data, ...obs.data }
            }
            const dms = this.getDMSLocation(obs.location)
            if (dms) {
              this.gpsLocation = { ...dms }
            }
            this.title = this.canEdit ? 'Modifier la déclaration' : 'Voir la déclaration'
          }).catch((err) => {
            if (err.message.toString().match(/403/)) {
              this.$notify({
                title: 'Erreur',
                message: 'Vous n\'êtes pas autorisé à voir cette déclaration',
                type: 'error'
              })
              this.$router.push('/observations')
            } else {
              this.$notify({
                title: 'Erreur',
                message: 'Cette déclaration n\'a pas pu être chargée',
                type: 'error'
              })
              console.error(err)
            }
          })
      }
    },
    getCategorySpecies (obs) {
      const species = this.species.find((s) => s.id === obs.especeId)
      if (species) {
        const cat = this.categories.find((c) => c.group === species.group)
        this.obsCategory = cat.group
        this.$nextTick(() => {
          this.obsSpecies = species.id
        })
      } else if (obs.otherEspece) {
        this.obsCategory = 'autre'
        this.obsSpecies = obs.otherEspece
      }
    },
    getDMSLocation (pos) {
      if (pos.latitude && pos.longitude) {
        // eslint-disable-next-line camelcase
        const [latitudeDegree, latitudeMin, latitudeSeconds, latitudeDir] = Decimal2DMS(pos.latitude, 'latitude').split(/['"°]+/)
        // eslint-disable-next-line camelcase
        const [longitudeDegree, longitudeMin, longitudeSeconds, longitudeDir] = Decimal2DMS(pos.longitude, 'longitude').split(/['"°]+/)
        return {
          latitudeDegree,
          latitudeMin,
          latitudeSeconds,
          latitudeDir,
          longitudeDegree,
          longitudeMin,
          longitudeSeconds,
          longitudeDir
        }
      }
    }
  },
  mounted () {
    if (this.user) {
      this.loadData()
    }
  }
}
</script>

<style scoped lang="scss">
.el-header {
  height: 100%;
  margin-top: 20px;
  background-color: #f7f7f7;
  padding: 30px;
}
h4 {
  margin: 0;
}
h4, h5 {
  text-align: center;
}
.map-container {
  margin-top: 20px;
}
.location {
  text-align: left;
  margin-bottom: 5px;
    .gps {
    text-align: left;
    }
  .el-input {
  width: 70px;
  padding: 0;
  }
}
.client-name {
  display: block;
  width: 100%;
  span {
    text-align: center;
  }
}
</style>
